<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-popup classContent="popup-example" title="Nouvelle Profession" :active.sync="popupProfession">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Libelle Profession</span>
            </div>

            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" type="text" v-validate="'required'" name="libelle" v-model="Libelle_Profession" />
              <span class="text-danger text-sm"  v-show="errors.has('Libelle_Profession')">{{ errors.first('Libelle_Profession') }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="createProfession" :disabled="!validateForm">Enregistrer</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="Libelle_Profession = ''; status = false;">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <vs-popup classContent="popup-example" title="Modifier une Profession" :active.sync="popupProfessionUpdate">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Libelle Profession</span>
            </div>

            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" type="text" v-validate="'required'" name="Libelle_Profession" v-model="Libelle_Profession" />
              <span class="text-danger text-sm"  v-show="errors.has('Libelle_Profession')">{{ errors.first('Libelle_Profession') }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="updateProfession" :disabled="!validateForm">Modifier</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="Libelle_Profession = ''; status = false; popupProfessionUpdate = false;">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="AlignJustifyIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Profession</span>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table multiple v-model="selected" pagination max-items="10" search :data="professionData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="popupProfession=true">Nouvelle Profession</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="index">
                  ID
                </vs-th>
                <vs-th sort-key="Libelle_Profession">
                  Libellé Profession
                </vs-th>

                <vs-th sort-key="website">
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].Libelle_Profession">
                    {{data[indextr].Libelle_Profession}}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateProfessionShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupProfession: false,
      popupProfessionUpdate: false,
      Libelle_Profession: '',
      selected: [],
      professionId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.Libelle_Profession !== ''
    },
    professionData () {
      return this.$store.state.profession.professions
    }
  },

  methods: {
    createProfession () {
      const payload = {
        Libelle_Profession: this.Libelle_Profession
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('profession/addProfession', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          //this.popupProfession = false
          this.resete_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupProfession = false
        })
    },
    confirmDeleteRecord (data) {
      this.professionId = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `voulez-vous vraiment supprimer "${data.Libelle_Profession}"`,
        accept: this.deleteRecord(data),
        acceptText: 'Supprimer'
      })
    },
    deleteRecord (data) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('profession/deleteProfession', this.professionId)
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.showDeleteSuccess(data)
        })
    },
    showDeleteSuccess (data) {
      this.$vs.notify({
        color: 'success',
        title: 'success',
        text: `${data.Libelle_Profession} supprimé`
      })
    },
    getProfession () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('profession/getProfessions')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    updateProfessionShowForm (data) {
      this.Libelle_Profession = data.Libelle_Profession
      this.categorieId = data._id
      this.popupProfessionUpdate = true
    },
    updateProfession () {
      const payload = {
        Libelle_Profession: this.Libelle_Profession,
        id: this.categorieId
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('profession/updateProfession', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          this.resete_data()
          this.getProfession()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupProfessionUpdate = false
          this.getProfession()
        })
    },
    resete_data () {
      this.Libelle_Profession = ''
    }
  },
  created () {
    this.getProfession()
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>

