

<template>
    <profession-list-vue></profession-list-vue>
</template>
<script>
import professionListVue from './profession_list.vue'
//import ProfessionList from ./profession/profession_listVue
export default {
  name:'ProfessionView',
  components:{
    professionListVue
  }
    
}
</script>